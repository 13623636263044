import {
    GET_PRODUCTS_BY_SKU_QUERY
} from '../../graphql/queries/productQueries'

import {
    SUBMIT_QUOTE_QUERY
} from '../../graphql/queries/quoteQueries'

const { default: Axios } = require("axios")

const state = {
    quote: localStorage.quote ? JSON.parse(localStorage.quote) : {},
    quoteCount: localStorage.quoteCount ? localStorage.quoteCount : 0,
    quoteProducts: {},
    quoteDealer: localStorage.quoteDealer ? localStorage.quoteDealer : "",
    isFetchingQuotes: false,
}

const getters = {
    quote: state => state.quote,
    quoteCount: state => state.quoteCount,
    quoteProducts: state => state.quoteProducts,
    quoteDealer: state => state.quoteDealer,
    isFetchingQuotes: state => state.isFetchingQuotes
}

const actions = {
    async fetchQuoteProducts({ commit, state }) {
        if (!state.isFetchingQuotes) {
            commit("SET_FETCHING_QUOTE")
            let skus = localStorage.quote ? Object.keys(JSON.parse(localStorage.quote)) : [];
            let pageSize = skus.length;
            if (skus.length > 0) {
                try {
                    const response = await Axios(GET_PRODUCTS_BY_SKU_QUERY(skus, pageSize))
                    if (response.errors) {
                        throw new Error('Exception message');
                    } else {
                        commit("SET_QUOTE_PRODUCTS", response.data)
                    }
                } catch (error) {
                    throw new Error('quote.js - fetchQuoteProducts ');
                }
            } else {
                commit("SET_QUOTE_PRODUCTS", { products: {} })
            }
        }
    },
    async storeProductsToQuote({ commit }, data) {
        if (!localStorage.quote) {
            localStorage.quote = JSON.stringify({})
        }
        let quote = JSON.parse(localStorage.quote)
        if (quote[data.sku]) {
            quote[data.sku] += data.productQuantity
        } else {
            quote[data.sku] = data.productQuantity
        }
        localStorage.quote = JSON.stringify(quote)
        commit('SET_QUOTE', quote)
        commit("UPDATE_QUOTE_COUNT", quote)
    },
    async updateProductFromQuote({ commit }, data) {
        let quote = JSON.parse(localStorage.quote)
        quote[data.sku] = data.quantity;
        localStorage.quote = JSON.stringify(quote)
        commit('UPDATE_PRODUCT_FROM_QUOTE_PRODUCTS', { quote: quote, sku: data.sku });
        commit("UPDATE_QUOTE_COUNT", quote)
    },
    async removeProductFromQuote({ commit, dispatch }, sku) {
        let quote = JSON.parse(localStorage.quote)
        delete quote[sku]
        localStorage.quote = JSON.stringify(quote)
        commit('REMOVE_PRODUCT_FROM_QUOTE_PRODUCTS', sku)
        dispatch('fetchQuoteProducts');
        commit("UPDATE_QUOTE_COUNT", quote)
    },
    async setQuoteDealer({ commit }, data) {
        commit('SET_QUOTE_DEALER', data.quoteDealer);
    },
    async submitQuote({ state, commit }, data) {
        const response = await Axios(SUBMIT_QUOTE_QUERY(data, state.quote))
        if (response.data.errors) {
            throw new Error("Something went wrong submitting quote.");
        } else {
            commit("CLEAR_QUOTE")
            return response.data.createQuoteRequest.quote_request;
        }
    },
}

const mutations = {
    SET_QUOTE: (state, quote) => {
        state.quote = quote;
    },
    SET_FETCHING_QUOTE: (state) => {
        state.isFetchingQuotes = true;
    },
    SET_QUOTE_PRODUCTS: (state, quoteProducts) => {
        state.quoteProducts = quoteProducts.products;
        state.isFetchingQuotes = false;
    },
    UPDATE_PRODUCT_FROM_QUOTE_PRODUCTS: (state, data) => {
        state.quote = data.quote;
    },
    REMOVE_PRODUCT_FROM_QUOTE_PRODUCTS: (state, sku) => {
        delete state.quote[sku];
    },
    UPDATE_QUOTE_COUNT: (state, quote) => {
        state.quoteCount = 0;
        for (var key in quote) {
            state.quoteCount += quote[key];
        }
        localStorage.quoteCount = state.quoteCount;
    },
    SET_QUOTE_DEALER: (state, quoteDealer) => {
        state.quoteDealer = quoteDealer;
        localStorage.quoteDealer = quoteDealer;
    },
    CLEAR_QUOTE: (state) => {
        localStorage.removeItem('quote')
        localStorage.removeItem('quoteCount')
        state.quoteCount = 0
        state.quote = {}
        state.quoteProducts = {}
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
