export const GET_CATEOGIRES_QUERY = () => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        data: {
            query: `{
                categories {
                    items {
                        children {
                            id
                            description
                            name
                            url_key
                            url_path
                            image
                            product_count
                            position
                            meta_title
                            meta_description
                            children {
                                id
                                description
                                name
                                url_path
                                url_key
                                image
                                product_count
                                position
                                meta_title
                                meta_description
                            }
                        }
                    }
                }
            }`
        },
        method: 'POST'
    }
}
