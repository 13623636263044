import { RETRIEVE_DEALERS_QUERY } from '../../graphql/queries/dealerQueries'

const { default: Axios } = require("axios")

const state = {
    vetusdealers: {}
}

const getters = {
    vetusdealers: state => state.vetusdealers
}

const actions = {
    async fetchDealers({ commit }) {
        try {
            const response = await Axios(RETRIEVE_DEALERS_QUERY());
            commit('setDealers', { dealers: response.data.dealers.items })
        } catch (error) {
            console.log(error);
            throw new Error('Something went wrong fetching dealers');
        }
    }
}

const mutations = {
    setDealers: (state, data) => {
        state.vetusdealers = data.dealers
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
