export const DEFAULT_CART_MODEL = `{
    id
    items {
        id
        quantity
        product {
            id
            name
            image { url }
            sku
            stock_status
            url_key
            price_range { minimum_price { regular_price { value currency } } }
            short_description { html }
            customer_price {
                final_price {
                    currency
                    value
                }
                regular_price {
                    currency
                    value
                }
            }
            customer_stock
        }
    }
    prices {
        subtotal_excluding_tax {
            value
            currency
        }
        applied_taxes {
            label
            amount {
                value
                currency
            }
        }
        grand_total{
            value
            currency
        }
    }
    available_payment_methods {
        code
        title
    }
}`
