import { DEFAULT_CART_MODEL } from '../models/cartModels'

export const CREATE_GUEST_CART_QUERY = () => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation { createEmptyCart }`
        }
    }
}


export const FETCH_CUSTOMER_CART_QUERY = () => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `{
                customerCart {
                    id
                }
            }`
        }
    }
}


export const ADD_PRODUCTS_TO_CART_QUERY = (cartId, productQuantity, sku) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                addProductsToCart(
                    cartId: "${cartId}"
                    cartItems: [
                        {
                            quantity: ${productQuantity}
                            sku: "${sku}"
                        }
                    ]
                ) {
                    cart ${DEFAULT_CART_MODEL}
                }
            }`
        }
    }
}

export const REMOVE_PRODUCTS_FROM_CART_QUERY = (cartId, cartItemId) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                removeItemFromCart(
                    input: {
                        cart_id: "${cartId}"
                        cart_item_id: ${cartItemId}
                    }
                ) {
                    cart ${DEFAULT_CART_MODEL}
                }
            }`
        }
    }
}

export const UPDATE_PRODUCTS_FROM_CART_QUERY = (cartId, cartItemId, cartItemQuantity) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                updateCartItems(
                    input: {
                        cart_id: "${cartId}"
                        cart_items: [
                            {
                            cart_item_id: ${cartItemId}
                            quantity: ${cartItemQuantity}
                            }
                        ]
                    }
                ) {
                    cart {
                        id
                     }
                }
            }`
        }
    }
}

export const RETRIEVE_CART_QUERY = (cartId) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `{
                cart(
                    cart_id: "${cartId}"
                )
                ${DEFAULT_CART_MODEL}

            }`
        }
    }
}

export const MERGE_CARTS_QUERY = (guestCartId, customerCartId) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                mergeCarts (
                    source_cart_id: "${guestCartId}",
                    destination_cart_id: "${customerCartId}"
                )
                {
                    items {
                        id
                        quantity
                        product {
                            id
                            name
                            image { url }
                            thumbnail {
                                url
                            }
                            sku
                            stock_status
                            url_key
                            price_range { minimum_price { regular_price { value currency } } }
                            customer_price {
                                final_price {
                                    currency
                                    value
                                }
                                regular_price {
                                    currency
                                    value
                                }
                            }

                        }
                    }
                    prices {
                        subtotal_excluding_tax {
                            value
                            currency
                        }
                        applied_taxes {
                            label
                            amount {
                                value
                                currency
                            }
                        }
                    }
                }

            }`
        }
    }
}

export const SET_BILLING_ADDRESS_ON_CART_QUERY = (cartId, data) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                setBillingAddressOnCart(
                    input: {
                        cart_id: "${cartId}"
                        billing_address: {
                            customer_address_id: ${data}
                        }
                    }
                ) {
                    cart {
                        billing_address {
                            firstname
                            lastname
                            company
                            street
                            city
                            region{
                                code
                                label
                            }
                            postcode
                            telephone
                            country {
                                code
                                label
                            }
                        }
                    }
                }
            }`
        }
    }
}

export const SET_SHIPPING_ADDRESS_ON_CART_QUERY = (cartId, data) => {
    let address;
    if (data.id) {
        address = `
            customer_address_id: ${data.id}
        `
    } else {
        address = `
            address: {
                firstname: "${data.firstname}"
                lastname: "${data.lastname}"
                street: ["${data.street}"]
                city: "${data.city}"
                region: "${data.region}"
                postcode: "${data.postcode}"
                country_code: "${data.country_code}"
                telephone: "${data.telephone}"
                company: "${data.company}"
                save_in_address_book: false
            }
        `
    }

    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                setShippingAddressesOnCart(
                    input: {
                        cart_id: "${cartId}"
                        shipping_addresses: {
                            ${address}
                        }
                    }
                ) {
                    cart {
                        shipping_addresses {
                            firstname
                            lastname
                            company
                            street
                            city
                            region{
                                code
                                label
                            }
                            postcode
                            telephone
                            country {
                                code
                                label
                            }
                        }
                    }
                }
            }`
        }
    }
}

export const SET_PAYMENT_METHOD_ON_CART_QUERY = (cartId, data) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                setPaymentMethodOnCart(
                    input: {
                        cart_id: "${cartId}"
                        payment_method: {
                            code: "${data}"
                        }
                    }
                ) {
                    cart {
                        selected_payment_method {
                            code
                        }
                    }
                }
            }`
        }
    }
}

export const SET_SHIPPING_METHOD_ON_CART_QUERY = (cartId, data) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                setShippingMethodsOnCart(
                    input: {
                        cart_id: "${cartId}"
                        shipping_methods: [
                            {
                                carrier_code: "${data.carrier_code}"
                                method_code: "${data.method_code}"
                            }
                        ]
                    }
                ) {
                    cart {
                        shipping_addresses {
                            selected_shipping_method {
                                carrier_code
                                method_code
                                carrier_title
                                method_title
                            }
                        }
                    }
                }
            }`
        }
    }
}
