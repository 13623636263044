import VueAxios from 'vue-axios'
import {
    // CREATE_GUEST_CART_QUERY,
    FETCH_CUSTOMER_CART_QUERY,
    ADD_PRODUCTS_TO_CART_QUERY,
    REMOVE_PRODUCTS_FROM_CART_QUERY,
    UPDATE_PRODUCTS_FROM_CART_QUERY,
    RETRIEVE_CART_QUERY,
    // MERGE_CARTS_QUERY
} from '../../graphql/queries/cartQueries'

const { default: Axios } = require("axios")

const state = {
    customerCartId: localStorage.getItem("customer_cart_id") || null,
    cart: {},
    cartCount: 0,
    productSkus: [],
    isFetchingCart: false,
}

const getters = {
    customerCartId: state => state.customerCartId,
    cart: state => state.cart,
    cartCount: state => state.cartCount,
    isFetchingCart: state => state.isFetchingCart
}

const actions = {
    async fetchCartId({ dispatch, rootState }) {
        if (rootState.auth.token == null) {
            throw new Error("Remove me! NOT ALLOWED")
        } else {
            dispatch("retrieveCustomerCartId");
        }

        return;
    },

    async retrieveCustomerCartId({ dispatch, commit, rootState }) {
        if (rootState.auth.token != null) {

            const response = await Axios(FETCH_CUSTOMER_CART_QUERY());
            if (response.data.errors) {
                // TODO [VETUS-316]: Check if error relates to authentication
                dispatch("signOut");
                throw 401;
            } else {
                commit("SET_CART_ID", response.data.customerCart.id)
                localStorage.setItem('customer_cart_id', response.data.customerCart.id);
                dispatch("retrieveCart");
            }
        }
    },

    async addProducts({ commit, state }, data) {
        let cartId = state.customerCartId;
        const response = await Axios(ADD_PRODUCTS_TO_CART_QUERY(cartId, data.productQuantity, data.sku))
        if (response.errors) {
            throw new Error('Exception message');
        } else {
            commit("SET_CART", response.data.addProductsToCart.cart);
        }
    },

    async removeProducts({ commit, state }, cartItemId) {
        let cartId = state.customerCartId;
        const response = await Axios(REMOVE_PRODUCTS_FROM_CART_QUERY(cartId, cartItemId))
        // commit("SET_CART", response.data.removeItemFromCart.cart);
        if (response.errors) {
            throw new Error('Exception message');
        } else {
            commit("UPDATE_CART", { cartItemId });
        }
    },

    async updateProducts({ commit, state }, data) {

        // Always commit, catch possible errors later
        commit("UPDATE_CART", data);

        let cartId = state.customerCartId;
        const response = await Axios(UPDATE_PRODUCTS_FROM_CART_QUERY(cartId, data.cartItemId, data.cartItemQuantity));

        // @TODO: Catch error and handle user friendly
        if (response.errors) {
            throw new Error('Could not update product in cart');
        }
    },

    async retrieveCart({ commit, state }) {
        if (!state.isFetchingCart) {
            state.isFetchingCart = true;
            let cartId = state.customerCartId;

            if (cartId) {
                const response = await Axios(RETRIEVE_CART_QUERY(cartId))
                if (response.errors) {
                    commit("SET_CART", {});
                } else {
                    commit("SET_CART", response.data.cart);
                }
            }
            else
                commit("SET_CART", {});

        }
    },

    async orderPlacedRefresh({ dispatch }) {
        dispatch('emptyCart')
        dispatch('retrieveCustomerCartId')
    },

    emptyCart({ commit }) {
        commit("SET_CART", {});
        commit("SET_CART_ID", null);
        localStorage.removeItem('customer_cart_id');
    },
}

const mutations = {
    SET_CART_ID(state, customerCartId) {
        state.customerCartId = customerCartId
    },
    SET_FETCHING_CART(state) {
        state.isFetchingCart = true;
    },
    SET_CART(state, cart) {
        state.cart = cart;
        state.isFetchingCart = false;
        if (JSON.stringify(cart) != '{}' && cart != null && cart.items.length > 0) {
            let cartCount = 0;
            cart.items.forEach(function (entry) {
                cartCount = cartCount + entry.quantity
            })
            state.cartCount = cartCount
        } else {
            state.cartCount = 0
        }
    },
    UPDATE_CART(state, data) {
        state.cart.items.forEach((item, key) => {
            if (item.id == data.cartItemId && data.cartItemQuantity) {
                item.quantity = data.cartItemQuantity;
            } else if (item.id == data.cartItemId) {
                state.cart.items.splice(key, 1);
            }
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
