{
    "home": {
        "header": {
            "title": "E-DRIVES",
            "subtitle": "The electric boating solutions from VETUS",
            "image": "https://vetus.com/cms/wp-content/uploads/e-pod_e-line_voor_web_2048x955px-1.png",
            "button_label": "Discover the E-DRIVES",
            "button_url": "https://vetus.com/e-drives/"
        },
        "categories": {
            "title": "VETUS Systems",
            "content": ""
        },
        "title": "Make boating enjoyable",
        "description": ""
    },
    "account": {
        "login": {
            "title": "Sign in to VETUS",
            "description": "",
            "seo": {
                "title": "Sign in to VETUS",
                "description": ""
            },
            "email-label": "Email address",
            "email-placeholder": "Your email address",
            "password-label": "Password",
            "password-placeholder": "Your password",
            "forgot-password": "Forgot password?",
            "sign-in": "Sign in",
            "no-account": "No account yet?",
            "request-access-button-label": "Request access",
            "request-access-button-url": "https://vetus.com/service-support/contact-form/",
            "error": "Something went wrong:",
            "sign-in-incorrect": "Account sign-in was incorrect"
        },
        "forgot-password": {
            "title": "Forgot your password?",
            "description": "Please enter your email address below. You will receive a link to reset your password.",
            "seo": {
                "title": "Reset password",
                "description": ""
            },
            "back-to-sign-in": "Back to sign-in",
            "request-granted": "Password reset requested",
            "error": "Password reset could not be requested",
            "submit": "Submit"
        },
        "reset-password": {
            "title": "Reset password",
            "description": "Please enter your email address below. You will receive a link to reset your password.",
            "seo": {
                "title": "Reset password",
                "description": ""
            },
            "email-label": "Email address",
            "email-placeholder": "Email address",
            "new-password-label": "New password",
            "new-password-placeholder": "Enter a new password",
            "back-to-sign-in": "Back to sign-in",
            "submit": "Submit",
            "error": "Something went wrong:",
            "success": "Password changed succesfully"
        },
        "sidemenu": {
            "account": "Account",
            "orders": "Web Orders",
            "processed-orders": "Offline Orders",
            "address-book": "Address book"
        },
        "sidebar": {
            "my-account": "My account"
        },
        "dashboard": {
            "title": "Dashboard",
            "description": "",
            "seo": {
                "title": "Dashboard",
                "description": ""
            },
            "greeting": "Hello, {name}",
            "content": "From your Account Dashboard you have the ability to view a snapshot of your recent account activity and update your account information. Select a link below to view or edit information.",
            "recent-order": {
                "title": "Recent order",
                "view-all": "View all"
            },
            "account": {
                "information": "Account information",
                "contact": "Contact information",
                "change-password": "Change password",
                "newsletters": "Newsletters",
                "not-subscribed": "You are currently not subscribed to any newsletter.",
                "edit": "Edit"
            },
            "address-book": {
                "title": "Address book",
                "manage-address": "Manage address",
                "default-billing": "Default billing address",
                "no-billing": "You have no billing address yet.",
                "default-shipping": "Default Shipping Address",
                "edit": "Edit",
                "not-subscribed": "You are currently not subscribed to any newsletter.",
                "request-a-change": {
                    "title": "Request a change",
                    "url": "https://vetus.com/service-support/contact-form/"
                }
            }
        },
        "orders": {
            "title": "Orders",
            "description": "",
            "seo": {
                "title": "Orders",
                "description": ""
            },
            "search-placeholder": "Search by order number",
            "show-more": "Show more"
        },
        "processed-orders": {
            "title": "Orders",
            "description": "",
            "seo": {
                "title": "Orders",
                "description": ""
            },
            "search-placeholder": "Search by order number",
            "show-more": "Show more"
        },
        "address-book": {
            "default-billing": "Default billing address",
            "request-change": "Request change",
            "default-shipping": "Default shipping address",
            "shipping-dropdown": "Open this select menu",
            "save": "Save default shipping address",
            "title": "Address book",
            "seo": {
                "title": "Address book",
                "description": ""
            },
            "description": ""
        }
    },
    "search": {
        "title": "Search",
        "description": "",
        "seo": {
            "title": "Search",
            "description": ""
        },
        "search-results": "Search Results"
    },
    "miscellaneous": {
        "labels": {
            "choose-variant": "Choose type",
            "quantity": "Quantity",
            "catalog-price": "Catalog price",
            "discount-rate": "Discount rate",
            "in-stock": "In stock",
            "out-of-stock": "Out of stock",
            "remove": "Remove",
            "sort-by": "Sort by",
            "relevance": "Relevance",
            "name-a-z": "Name A-Z",
            "name-z-a": "Name Z-A",
            "product": "Product",
            "contact-info": "Contact-info",
            "loading": "Loading",
            "no-results": "No results"
        },
        "buttons": {
            "add-to-cart": "Add to cart",
            "add-to-quote": "Add to quote",
            "show-more": "Show more",
            "show-less": "Show less",
            "filter": "Filter"
        },
        "titles": {
            "related-products": "Related products",
            "description": "Description",
            "specifications": "Specifications"
        }
    },
    "shop": {
        "cart": {
            "title": "Cart",
            "description": "",
            "product-added": "Product added to",
            "seo": {
                "title": "Cart",
                "description": ""
            },
            "total": "Total",
            "empty": "Your cart is empty. Visit the webshop to add products to your cart.",
            "proceed": "Proceed to checkout"
        },
        "cart-popup": {
            "title": "Product added to cart",
            "continue": "Continue shopping",
            "view": "View cart",
            "optional": "You may also need"
        },
        "quote-popup": {
            "view": "View quote"
        },
        "checkout": {
            "title": "Checkout",
            "description": "",
            "seo": {
                "title": "Checkout",
                "description": ""
            }
        },
        "quote": {
            "title": "Quote",
            "description": "",
            "seo": {
                "title": "Quote",
                "description": ""
            },
            "proceed": "Proceed to contact info",
            "proceed-dealer": "Continue to choose a dealer",
            "empty": "Your quote is empty. Visit the webshop to add products to your quote.",
            "check-the-products": {
                "tab": "Check your products"
            },
            "choose-a-dealer": {
                "tab": "Choose a dealer",
                "title": "Choose a dealer",
                "description": "",
                "seo": {
                    "title": "Choose a dealer",
                    "description": ""
                }
            },
            "contact-info": {
                "tab": "Contact info",
                "title": "Contact info",
                "description": "",
                "seo": {
                    "title": "Contact info",
                    "description": "Contact info description"
                }
            },
            "thanks-title": "Thank you for your quote request!",
            "request-number": "Your quote request # is:",
            "confirmation": "You will receive a quote confirmation email with details of your quote"
        }
    },
    "section": {
        "intro": ""
    },
    "header": {
        "usp": {
            "usp1": "More than 55 years of experience",
            "usp2": "Worldwide supplier",
            "usp3": "5 global warehouses",
            "usp4": "5 PLUS warranty"
        },
        "units": {
            "units": "Units",
            "metric-selected": "Metric",
            "metric-option": "Metric (cm, liter)",
            "imperial-selected": "Imperial",
            "imperial-option": "Imperial (inch, gallon)"
        },
        "websites": {
            "corporate": "Corporate",
            "commercial": "Commercial",
            "webshop": "Products"
        },
        "menu": {
            "home": "Home",
            "products": "Products",
            "all-products": "View all products",
            "outlet": "Outlet",
            "service-support": "Service & Support",
            "partsfinder": "Partsfinder",
            "cart": "Cart",
            "quote": "Quote",
            "my-profile": "My profile",
            "account": "Account",
            "login": "Login",
            "logout": "Logout",
            "search": "Search",
            "search-bar": "Open search bar"
        },
        "about-us": "About us",
        "creator-of": "Creators of",
        "boat-systems": "Boat Systems",
        "about-intro": ""
    },
    "footer": {
        "products-title": "Products",
        "my-profile": "My VETUS",
        "cart": "Cart"
    },
    "languages": {
        "language": "Language",
        "en": "English",
        "nl": "Dutch"
    },
    "newsletter": {
        "title": "Subscribe to our newsletter",
        "content": "Receive the latest information about offers, promotions and news",
        "placeholder": "Your email address",
        "subscribe": "Subscribe",
        "link": "#"
    },
    "404-not-found": {
        "title": "Whoops! You are currently in uncharted waters",
        "content": "This page has gone missing or the URL doesn't exists.",
        "button": "Return home"
    },
    "products": {
        "title": "Products",
        "description": "",
        "seo": {
            "title": "Products",
            "description": ""
        },
        "errors": {
            "select-variant": "Please select a variant to continue"
        }
    },
    "order": {
        "billing-address": "Billing address",
        "shipping-address": "Shipping address",
        "reference": "Your reference",
        "first-name": "First name",
        "last-name": "Last name",
        "first-name-mand": "First name *",
        "last-name-mand": "Last name *",
        "email": "Email",
        "email-mand": "Email *",
        "share-email": "We'll never share your email with anyone else.",
        "address": "Address",
        "address-mand": "Address *",
        "state-province": "State / province",
        "state-province-mand": "State / province *",
        "postal-code": "Postal code",
        "postal-code-mand": "Postal code *",
        "phone": "Phone number",
        "city-town": "City / town",
        "city-town-mand": "City / town *",
        "country": "Country",
        "country-mand": "Country *",
        "company": "Company",
        "optional": "Optional",
        "download-invoice": "Download invoice",
        "new-shipping-address": "Add new shipping address",
        "submit-request": "Submit Request",
        "no-billing-address": "No billing address found.",
        "shipping-method": "Shipping method",
        "no-shipping-method": "No shipping method found.",
        "no-shipping-address": "No shipping address found.",
        "payment-method": "Payment method",
        "no-payment-method": "No payment method found.",
        "items-ordered": "Items ordered",
        "subtotal": "Subtotal",
        "shipping-handling": "Shipping & handling",
        "estimate-shipping-handling": "Estimate shipping & handling costs",
        "carriage": "Carriage paid to - Shipping costs are calculated after processing",
        "tax": "tax",
        "order-summary": "Order summary",
        "adjust": "Adjust",
        "request-total": "Request total",
        "thanks": "Thank you for your purchase!",
        "order-received": "Order received",
        "order-number": "Order number",
        "order-hash": "Order #",
        "status": "status",
        "view-order": "View order",
        "order-confirmation": "Order confirmation",
        "about-order": "About this order",
        "errors": {
            "something-wrong": "Something went wrong. Please try again later or contact us.",
            "something-wrong-shipping": "Something went wrong with the shipping address. Please try again later or contact us.",
            "something-wrong-shipping-method": "Something went wrong with the shipping method. Please try again later or contact us.",
            "something-wrong-billing": "Something went wrong with the billing address. Please try again later or contact us.",
            "something-wrong-payment": "Something went wrong with the payment method. Please try again later or contact us."
        }
    },
    "processed-order": {
        "order-number": "Order number",
        "status": "Status",
        "delivery-date": "Expected delivery",
        "download-invoice": "Download invoice"
    },
    "contact-info": {
        "comment": "Comment",
        "newsletter-signup": "Yes, I would like to subscribe to your newsletter and receive the latest information about offers, promotions and news.",
        "request-send": "Request will be sent to",
        "quote": "Your quote is empty. Visit the webshop to add products to your quote.",
        "errors": {
            "something-wrong": "Something went wrong submitting your quote."
        }
    },
    "carousel": {
        "previous": "previous",
        "next": "next"
    },
    "store-locator": {
        "reset-filters": "Reset filter",
        "enter-dealer-name": "Enter dealer name",
        "postal-code-city-country": "Postal code, city or country",
        "choose-dealer-for-quote": "Choose dealer for quote",
        "no-results": "No results",
        "title": "More than 1600 stores",
        "intro": "",
        "search-location": "Search for location",
        "search-name": "Search for dealer name"
    },
    "events": {
        "latest-events": "Latest events",
        "all-events-button-label": "All events",
        "all-events-button-url": "https://vetus.com/boat-shows",
        "past-event": "Past event"
    },
    "news": {
        "latest-news": "Latest news",
        "all-news-button-label": "All news",
        "all-news-button-url": "https://vetus.com/news"
    }
}
