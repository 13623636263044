import {
    SET_BILLING_ADDRESS_ON_CART_QUERY,
    SET_SHIPPING_ADDRESS_ON_CART_QUERY,
    SET_PAYMENT_METHOD_ON_CART_QUERY,
    SET_SHIPPING_METHOD_ON_CART_QUERY
} from '../../graphql/queries/cartQueries'
import {
    PLACE_ORDER_QUERY,
    RETRIEVE_CUSTOMER_ORDERS_QUERY,
    RETRIEVE_CUSTOMER_ORDER_QUERY
} from '../../graphql/queries/orderQueries'

const { default: Axios } = require("axios")

const state = {
    orders: {},
    order: {},
    isFetchingOrders: false,
    isFetchingOrder: false,
}

const getters = {
    orders: state => state.orders,
    order: state => state.order,
    isFetchingOrders: state => state.isFetchingOrders,
    isFetchingOrder: state => state.isFetchingOrder
}

const actions = {
    async setBillingAddress({ rootState }, data) {
        const response = await Axios(SET_BILLING_ADDRESS_ON_CART_QUERY(rootState.cart.customerCartId, data))

        if (response.data.errors) {
            throw new Error("Something went wrong setting billing address.", response.data.errors);
        } else {
            return response;
        }
    },
    async setShippingAddress({ rootState }, data) {
        const response = await Axios(SET_SHIPPING_ADDRESS_ON_CART_QUERY(rootState.cart.customerCartId, data))
        if (response.data.errors) {
            throw new Error("Something went wrong setting shipping address.", response.data);
        } else {
            return response;
        }
    },
    async setPaymentMethod({ rootState }, data) {
        const response = await Axios(SET_PAYMENT_METHOD_ON_CART_QUERY(rootState.cart.customerCartId, data))
        if (response.data.errors) {
            throw new Error("Something went wrong setting payment method.");
        } else {
            return response;
        }
    },
    async setShippingMethod({ rootState }, data) {
        const response = await Axios(SET_SHIPPING_METHOD_ON_CART_QUERY(rootState.cart.customerCartId, data))

        if (response.data.errors) {
            throw new Error("Something went wrong setting shipment method.");
        } else {
            return response;
        }
    },
    async placeOrder({ rootState, dispatch }, data) {
        const response = await Axios(PLACE_ORDER_QUERY(rootState.cart.customerCartId, data))
        if (response.data.errors) {
            throw new Error("Something went wrong setting billing address.");
        } else {
            dispatch('orderPlacedRefresh'); // modules/cart.js
            return response.data.placeOrder.order;
        }
    },
    async retrieveOrders({ commit, dispatch }, data) {
        if (!state.isFetchingOrders) {
            commit('SET_FETCHING_ORDERS')
            let query = ``;
            if (data && data.search) {
                query = `filter: { number: {match: "${data.search}"} }`;
            }
            // console.log(query);
            const response = await Axios(RETRIEVE_CUSTOMER_ORDERS_QUERY(query))

            if (response.data.errors) {
                // console.log("retrieveOrders");
                // console.log(response.data.errors);
                throw new Error("Something went wrong retrieving orders");
            } else if (response.data.customer == null) {
                // TODO [VETUS-316]: Check if error relates to authentication
                dispatch("signOut");
                throw 401;
            } else {
                commit("SET_ORDERS", response.data.customer.orders);

            }
        }
    },
    async retrieveOrder({ commit }, data) {
        if (!state.isFetchingOrder) {
            commit('SET_FETCHING_ORDER')
            let query = ``;
            if (data && data.search) {
                query = `filter: { number: {eq: "${data.search}"} }`;
            }
            const response = await Axios(RETRIEVE_CUSTOMER_ORDER_QUERY(query))
            if (response.data.errors) {
                throw new Error("Something went wrong retrieving orders");
            } else {
                commit("SET_ORDER", response.data.customer.orders.items[0]);
            }
        }
    }
}

const mutations = {
    SET_ORDERS(state, orders) {
        state.orders = orders.items.reverse();
        state.orders_count = orders.count;
        state.isFetchingOrders = false;
    },
    SET_ORDER(state, order) {
        state.order[order.order_number] = order;
        state.isFetchingOrder = false;
    },
    SET_FETCHING_ORDERS: (state,) => {
        state.isFetchingOrders = true;
    },
    SET_FETCHING_ORDER: (state) => {
        state.isFetchingOrder = true;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
