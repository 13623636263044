
// export const GET_PRODUCTS_QUERY = (categoryId, sortOption) => {
//     return {
//         url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
//         method: 'POST',
//         data: {
//             query: `query {
//                 products(
//                     search: "",
//                     pageSize: 20, filter: { category_id: {eq: "${categoryId}"}}, sort: { ${sortOption} }
//                 ) {
//                     items {
//                         name
//                         sku
//                         image { url }
//                         stock_status
//                         url_key
//                         price_range { minimum_price { regular_price { value currency } } }
//                         ... on ConfigurableProduct {
//                             variants {
//                                 product {
//                                     id
//                                     name
//                                     sku
//                                 }
//                             }
//                         }
//                     }
//                     total_count
//                     page_info {
//                         page_size
//                     }
//                 }
//             }`
//         }
//     }
// }
export const GET_PRODUCTS_QUERY = (categoryId, filters) => {
    let pageSize = 24;
    let sortOption = filters.s ? filters.s : 'position:ASC';
    let pageNumber = filters.p ? filters.p : 1;
    let filterString = `category_id: {eq: "${categoryId}"}`;
    for (let [key, filter] of Object.entries(filters)) {
        if (!['category_id', 'price', 'p', 's'].includes(key)) {
            let array = filter.split(",").map(el => {
                return String(el);
            });
            array = JSON.stringify(array);
            filterString = filterString.concat(`, ${key}: {in: ${array}}`);
        }
    }
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        data: {
            query: `{
                products(
                    search: "",
                    pageSize: ${pageSize},
                    currentPage: ${pageNumber},
                    filter: { ${filterString} },
                    sort: { ${sortOption} }
                ) {
                    items {
                        name
                        sku
                        image { url }
                        small_image{
                            url
                            label
                        }
                        thumbnail {
                            url
                        }
                        stock_status
                        url_key
                        price_range { minimum_price { regular_price { value currency } } }
                        short_description { html }
                        customer_price {
                            final_price {
                                currency
                                value
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                        customer_stock
                        ... on ConfigurableProduct {
                            variants {
                                product {
                                    id
                                    name
                                    sku
                                }
                            }
                        }
                    }
                    aggregations {
                        attribute_code
                        count
                        label
                        options {
                            count
                            label
                            value
                        }
                    }
                    total_count
                    page_info {
                        page_size
                    }
                }
            }`,
        },
        method: 'POST'
    }
}

// @TODO: Combine with GET_PRODUCTS_QUERY
export const GET_SEARCH_PRODUCTS_QUERY = (sortOption, pageNumber, searchValueResult) => {
    let pageSize = 24;
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        data: {
            query: `{
                products(
                    pageSize: ${pageSize},
                    currentPage: ${pageNumber},
                    search: "${searchValueResult}",
                    sort: { ${sortOption} }
                ) {
                    items {
                        name
                        sku
                        image { url }
                        stock_status
                        url_key
                        price_range { minimum_price { regular_price { value currency } } }
                        short_description { html }
                        customer_price {
                            final_price {
                                currency
                                value
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                        customer_stock
                        ... on ConfigurableProduct {
                            variants {
                                product {
                                    id
                                    name
                                    sku
                                }
                            }
                        }
                    }
                    total_count
                    page_info {
                        page_size
                    }
                }
            }`,
        },
        method: 'POST'
    }
}


export const GET_PRODUCT_QUERY = (urlKey) => {
    let pageSize = 24;
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        data: {
            query: `{
                products(
                    search: "",
                    pageSize: ${pageSize},
                    filter: { url_key: { eq: "${urlKey}" } }
                ) {
                    items {
                        name
                        sku
                        image { url }
                        media_gallery { url }
                        url_key
                        stock_status
                        short_description { html }
                        description { html }
                        meta_title
                        meta_description
                        price_range { minimum_price { regular_price { value currency } } }
                        customer_price {
                            final_price {
                                currency
                                value
                            }
                            regular_price {
                                currency
                                value
                            }
                            discount {
                                amount_off
                                percent_off
                            }
                        }
                        customer_stock
                        attributes {
                            name
                            code
                            value
                            unit
                        }
                        related_products {
                            id
                            name
                            sku
                            url_key
                            price_range { minimum_price { regular_price { value currency } } }
                            image { url }
                            short_description {
                                html
                            }
                        }
                        product_links {
                            sku
                            link_type
                            linked_product_sku
                            linked_product_type
                            position
                        }
                        crosssell_products {
                            id
                            name
                            sku
                            url_key
                            price_range { minimum_price { regular_price { value currency } } }
                            image { url }
                            short_description {
                                html
                            }
                        }
                        ... on ConfigurableProduct {
                            variants {
                                product {
                                    id
                                    name
                                    sku
                                }
                            }
                        }
                    }
                    total_count
                    page_info {
                        page_size
                    }
                }
            }`,
        },
        method: 'POST'
    }
}

export const GET_PRODUCTS_BY_SKU_QUERY = (skus, pageSize) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        data: {
            query: `{
                products(
                    search: "",
                    pageSize: ${pageSize},
                    filter: {
                        sku: {
                            in: ${JSON.stringify(skus)}
                        }
                    }
                ) {
                    items {
                        name
                        sku
                        image { url }
                        stock_status
                        url_key
                        price_range { minimum_price { regular_price { value currency } } }
                        customer_price {
                            final_price {
                                currency
                                value
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                        customer_stock
                        short_description { html }
                        ... on ConfigurableProduct {
                            variants {
                                product {
                                    id
                                    name
                                    sku
                                }
                            }
                        }
                    }
                    total_count
                    page_info {
                        page_size
                    }
                }
            }`,
        },
        method: 'POST'
    }
}
