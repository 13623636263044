export const RETRIEVE_DEALERS_QUERY = () => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `{
                dealers {
                    items {
                        id
                        name,
                        position {
                            lat
                            lng
                        }
                        address {
                            street
                            city
                            postcode
                            country_code
                            telephone
                        }
                        email
                        website
                        tags
                        vetus_center
                    }
                }
            }`
        }
    }
}
