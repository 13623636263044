import { DEFAULT_ADDRESS } from '../models/addressModels'
export const PLACE_ORDER_QUERY = (cartId, data) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                placeOrder(
                    input: {
                        cart_id: "${cartId}"
                        order_reference: "${data.reference}"
                    }
                ) {
                    order {
                        order_number
                    }
                }
            }`
        }
    }
}

export const RETRIEVE_CUSTOMER_ORDERS_QUERY = (query) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `query {
                customer {
                    orders(
                        pageSize: 9999
                        ${query}
                    ) {
                        total_count
                        items {
                            id
                            order_number
                            order_date
                            total {
                                grand_total {
                                    value
                                    currency
                                }
                            }
                            status
                            reference
                        }
                    }
                }
            }`
        }
    }
}

export const RETRIEVE_CUSTOMER_ORDER_QUERY = (query) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `query {
                customer {
                    orders(
                        pageSize: 9999
                        ${query}
                    ) {
                        total_count
                        items {
                            id
                            order_number
                            order_date
                            reference
                            total {
                                grand_total {
                                    value
                                    currency
                                }
                            }
                            payment_methods {
                                name
                            }
                            shipping_method
                            billing_address ${DEFAULT_ADDRESS}
                            shipping_address ${DEFAULT_ADDRESS}
                            status
                            items {
                                product_name
                                product_sku
                                product_url_key
                                product_sale_price {
                                    value
                                    currency
                                }
                                quantity_ordered
                                quantity_invoiced
                                quantity_shipped
                            }
                            carrier
                            shipments {
                                id
                                number
                                items {
                                    product_name
                                    quantity_shipped
                                }
                            }
                            total {
                                base_grand_total {
                                    value
                                    currency
                                }
                                grand_total {
                                    value
                                    currency
                                }
                                total_tax {
                                    value
                                    currency
                                }
                                subtotal {
                                    value
                                    currency
                                }
                                taxes {
                                    amount {
                                        value
                                        currency
                                    }
                                    title
                                    rate
                                }
                                total_shipping {
                                    value
                                    currency
                                }
                                shipping_handling {
                                    amount_including_tax {
                                        value
                                        currency
                                    }
                                    amount_excluding_tax {
                                        value
                                        currency
                                    }
                                    total_amount {
                                        value
                                        currency
                                    }
                                    taxes {
                                        amount {
                                            value
                                        currency
                                        }
                                        title
                                        rate
                                    }
                                }
                                discounts {
                                    amount {
                                        value
                                        currency
                                    }
                                    label
                                }
                            }
                        }
                    }
                }
            }`
        },
    }
}
