import { RETRIEVE_COUNTRIES_QUERY } from '../../graphql/queries/countryQueries'

const { default: Axios } = require("axios")

const state = {
    countries: {}
}

const getters = {
    countries: state => state.countries
}

const actions = {
    async fetchCountries({ commit }) {
        try {
            const response = await Axios(RETRIEVE_COUNTRIES_QUERY());
           // console.log("s", response);
            commit('setCountries', { countries: response.data.countries })
        } catch (error) {
            //console.log(error);
            throw new Error('Something went wrong fetching countries');
        }
    }
}

const mutations = {
    setCountries: (state, data) => {
        state.countries = data.countries
        // state.countries = data.countries
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
