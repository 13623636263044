import { createRouter, createWebHistory } from "vue-router";
import store from './../store'
import i18n from '../i18n'

// const ifNotAuthenticated = (to, from, next) => {
//   // console.log(store.getters.isAuthenticated);
//   if (!store.getters.isLoggedIn) {
//     next({ name: 'Login' })
//     return
//   }
//   next({ name: 'Account Dashboard' })
// }

// const ifAuthenticated = (to, from, next) => {
//   if (store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next({ name: 'Login' })
// }

// const ifCart = (to, from, next) => {
//   console.log("kom ik hier?");
//   if (store.getters.cartCount == 0) {
//     next()
//     return;
//   }
//   next({ name: 'Home' })
// }


const routes = [
    {
        // All pages are preceded by /:locale
        path: '/:locale',
        name: 'Locale',
        component: () => import(/* webpackChunkName: "locale" */ './../Locale.vue'),

        beforeEach: setLocale,
        beforeEnter: setLocale,
        beforeRouteUpdate: setLocale,
        meta: {
            seo: {
                title: "Make boating enjoyable",
                description: "Everything to make your boating enjoyable",
            }
        },
        children: [
            {
                path: "",
                alias: "/",
                name: "Home",
                components: {
                    default: () => import(/* webpackChunkName: "home" */ './../views/Home.vue'),
                    headerContent: () => import(/* webpackChunkName: "header9" */ './../components/Headers/Header10.vue'),
                },
                meta: {
                    header: {
                        title: "Everything to make your boating enjoyable",
                        description: "Make boating enjoyable",
                    },
                    seo: {
                        title: "home.seo.title",
                        description: "home.seo.description",
                    }
                }
            },
            {
                path: "search/:searchSlug?",
                name: "SearchForm",
                components: {
                    default: () => import(/* webpackChunkName: "default" */ './../views/Search.vue'),
                    headerContent: () => import(/* webpackChunkName: "header5" */ './../components/Headers/Header5.vue'),
                },
                meta: {
                    header: {
                        title: "search.title",
                        desc: "search.description",
                    },
                    seo: {
                        title: "search.seo.title",
                        description: "search.seo.description",
                    },
                    breadcrumb: [
                        { name: '' }
                    ],
                },
            },
            {
                path: "cart",
                name: "Cart",
                components: {
                    default: () => import(/* webpackChunkName: "product" */ './../views/Shop/Cart.vue'),
                    headerContent: () => import(/* webpackChunkName: "header5" */ './../components/Headers/Header5.vue'),
                },
                meta: {
                    header: {
                        title: "shop.cart.title",
                        desc: "shop.cart.description",
                    },
                    seo: {
                        title: "shop.cart.seo.title",
                        description: "shop.cart.seo.description",
                    },
                    showBreadcrumb: false,
                    breadcrumb: [
                        { name: 'Account Dashboard' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: "quote",
                // name: "Quote",
                components: {
                    default: () => import(/* webpackChunkName: "product" */ './../views/Shop/Quote.vue'),
                    headerContent: () => import(/* webpackChunkName: "header5" */ './../components/Headers/Header5.vue'),
                },
                meta: {
                    breadcrumb: [
                        { name: '' }
                    ]
                },
                children: [
                    {
                        name: "Quote",
                        path: "",
                        component: () =>
                            import(/* webpackChunkName: "product" */ './../views/Shop/QuoteCart.vue'),
                        meta: {
                            header: {
                                title: "shop.quote.title",
                                description: "shop.quote.description",
                            },
                            seo: {
                                title: "shop.quote.seo.title",
                                description: "shop.quote.seo.description",
                            }
                        }

                    },
                    {
                        path: "choose-a-dealer",
                        name: "Choose a dealer",
                        component: () =>
                            import(/* webpackChunkName: "dashboard" */ './../views/Shop/ChooseDealer.vue'),
                        meta: {
                            requiresVisitor: true,
                            header: {
                                title: "shop.quote.choose-a-dealer.title",
                                description: "shop.quote.choose-a-dealer.description",
                            },
                            seo: {
                                title: "shop.quote.choose-a-dealer.seo.title",
                                description: "shop.quote.choose-a-dealer.seo.description",
                            }
                        }
                    },
                    {
                        path: "contact-info",
                        name: "Contact info",
                        component: () =>
                            import(/* webpackChunkName: "dashboard" */ './../views/Shop/ContactInfo.vue'),
                        meta: {
                            header: {
                                title: "shop.quote.contact-info.title",
                                description: "shop.quote.contact-info.description",
                            },
                            seo: {
                                title: "shop.quote.contact-info..contact-info.seo.title",
                                description: "shop.quote.contact-info..contact-info.seo.description",
                            }
                        }
                    }
                ]
            },
            {
                path: "quote/confirmation",
                name: "Confirmation Quote",
                components: {
                    default: () => import(/* webpackChunkName: "product" */ './../views/Shop/ConfirmationQuote.vue'),
                    headerContent: () => import(/* webpackChunkName: "header5" */ './../components/Headers/Header2Quote.vue'),
                },
                meta: {
                    breadcrumb: [
                        { name: '' }
                    ],
                }
            },
            {
                path: "checkout",
                name: "Checkout",
                components: {
                    default: () => import(/* webpackChunkName: "product" */ './../views/Shop/Checkout.vue'),
                    headerContent: () => import(/* webpackChunkName: "header5" */ './../components/Headers/Header5.vue'),
                },
                meta: {
                    header: {
                        title: "shop.checkout.title",
                        description: "shop.checkout.description",
                    },
                    seo: {
                        title: "shop.checkout.seo.title",
                        description: "shop.checkout.seo.description",
                    },
                    breadcrumb: [
                        { name: '' }
                    ]
                }
            },
            {
                path: "confirmation",
                name: "Confirmation",
                components: {
                    default: () => import(/* webpackChunkName: "product" */ './../views/Shop/Confirmation.vue'),
                    headerContent: () => import(/* webpackChunkName: "header5" */ './../components/Headers/Header2.vue'),
                },
                meta: {
                    breadcrumb: [
                        { name: '' }
                    ]
                }
            },
            {
                path: "account",
                name: "Account",
                component: () =>
                    import(/* webpackChunkName: "dashboard" */ './../views/Account/Account.vue'),
                redirect: '/account/dashboard',
                // beforeEnter: ifAuthenticated,
                children: [
                    {
                        path: 'dashboard',
                        name: 'Account Dashboard',
                        component: () =>
                            import(/* webpackChunkName: "dashboard" */ './../views/Account/Dashboard.vue'),
                        meta: {
                            header: {
                                // title: 'account.dashboard.title',
                                // description: 'account.dashboard.description',
                            },
                            seo: {
                                title: 'account.dashboard.seo.title',
                                description: 'account.dashboard.seo.description',
                            },
                            breadcrumb: [
                                { name: 'Account Dashboard' }
                            ],
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'orders',
                        name: "Orders",
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "orders" */ './../views/Account/Orders.vue'),
                        meta: {
                            header: {
                                title: 'account.orders.title',
                                description: 'account.orders.description',
                            },
                            seo: {
                                title: 'account.orders.seo.title',
                                description: 'account.orders.seo.description',
                            },
                            breadcrumb: [
                                { name: 'Account Dashboard', link: '/account' },
                                { name: 'Orders' }
                            ],
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'orders/:orderSlug',
                        name: "Order",
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "order" */ './../views/Account/Order.vue'),
                        meta: {
                            // Via component
                            // header: {
                            //     title: 'account.order.title',
                            //     description: 'account.order.description',
                            // },
                            // seo: {
                            //     title: 'account.order.seo.title',
                            //     description: 'account.order.seo.description',
                            // },
                            breadcrumb: [
                                { name: 'Account Dashboard', link: '/account' },
                                { name: 'Orders', link: '/account/orders' },
                                { name: 'Order' }
                            ],
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'processed-orders',
                        name: "Processed Orders",
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "orders" */ './../views/Account/ProcessedOrders.vue'),
                        meta: {
                            header: {
                                title: 'account.processedOrders.title',
                                description: 'account.processedOrders.description',
                            },
                            seo: {
                                title: 'account.processedOrders.seo.title',
                                description: 'account.processedOrders.seo.description',
                            },
                            breadcrumb: [
                                { name: 'Account Dashboard', link: '/account' },
                                { name: 'Processed Orders' }
                            ],
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'processed-orders/:orderSlug',
                        name: "Processed Order",
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "order" */ './../views/Account/ProcessedOrder.vue'),
                        meta: {
                            // Via component
                            // header: {
                            //     title: 'account.order.title',
                            //     description: 'account.order.description',
                            // },
                            // seo: {
                            //     title: 'account.order.seo.title',
                            //     description: 'account.order.seo.description',
                            // },
                            breadcrumb: [
                                { name: 'Account Dashboard', link: '/account' },
                                { name: 'Processed Orders', link: '/account/processed-orders' },
                                { name: 'Processed Order' }
                            ],
                            requiresAuth: true
                        }
                    },
                    //   {
                    //     path: 'address-book',
                    //     name: "Address book",
                    //     component: () =>
                    //       import(/* webpackChunkName: "addressbook" */ './../views/Account/AddressBook.vue'),
                    //     meta: {
                    //       breadcrumb: [
                    //         { name: 'Account Dashboard', link: '/account' },
                    //         { name: 'Address book' }
                    //       ],
                    //       requiresAuth: true
                    //     }
                    //   },
                    {
                        path: 'login',
                        name: 'Login',
                        component: () =>
                            import(/* webpackChunkName: "login" */ './../views/Account/Login.vue'),
                        meta: {
                            header: {
                                title: 'account.login.title',
                                description: 'account.login.description',
                            },
                            seo: {
                                title: 'account.login.seo.title',
                                description: 'account.login.seo.description',
                            },
                            breadcrumbs: true,
                            requiresAuth: false,
                            requiresVisitor: true,
                            breadcrumb: [
                                { name: '' }
                            ]
                        }
                    },
                    {
                        path: 'forgot-password',
                        name: "Forgot password",
                        component: () =>
                            import(/* webpackChunkName: "password" */ './../views/Account/Password.vue'),
                        meta: {
                            header: {
                                title: 'account.forgot-password.title',
                                description: 'account.forgot-password.description',
                            },
                            seo: {
                                title: 'account.forgot-password.seo.title',
                                description: 'account.forgot-password.seo.description',
                            },
                            title: 'Forgot password',
                            requiresVisitor: true,
                            breadcrumbs: true,
                            breadcrumb: [
                                { name: '' }
                            ]
                        },
                    },
                    {
                        path: 'reset-password',
                        name: "Reset password",
                        component: () =>
                            import(/* webpackChunkName: "password" */ './../views/Account/PasswordReset.vue'),
                        meta: {
                            header: {
                                title: 'account.reset-password.title',
                                description: 'account.reset-password.description',
                            },
                            seo: {
                                title: 'account.reset-password.seo.title',
                                description: 'account.reset-password.seo.description',
                            },
                            requiresVisitor: true,
                            breadcrumbs: true,
                            breadcrumb: [
                                { name: '' }
                            ]
                        },
                    }
                ]
            },
            {
                path: "products",

                component: () =>
                    import(/* webpackChunkName: "product" */ './../views/Products/Products.vue'),
                meta: {
                    header: {
                        title: 'products.title',
                        description: 'products.description',
                    },
                    seo: {
                        title: 'products.seo.title',
                        description: 'products.seo.description',
                    },
                    breadcrumbs: true,
                    breadcrumb: [
                        { name: 'Products', link: '/products' },
                    ]
                },
                children: [
                    {
                        name: "Products",
                        path: "",
                        component: () =>
                            import(/* webpackChunkName: "product" */ './../views/Products/Categories.vue'),
                    },
                    {
                        path: ":categorySlug",
                        name: "Category",
                        component: () =>
                            import(/* webpackChunkName: "product" */ './../views/Products/Categories.vue'),
                        props: true,
                        meta: {
                            title: 'Products',
                            breadcrumbs: true,
                            breadcrumb: [
                                { name: 'Products', link: '/products' },
                                {
                                    name: '',
                                    state: 'categories',
                                    routeParam: 'categorySlug',
                                    link: '/products/categorySlug',
                                },
                            ]
                        },
                        children: [
                            {
                                path: ":subCategorySlug",
                                name: "SubCategory",
                                component: () =>
                                    import(/* webpackChunkName: "product" */ './../views/Products/Categories.vue'),
                                props: true,
                                meta: {
                                    breadcrumbs: true,
                                    breadcrumb: [
                                        { name: 'Products', link: '/products' },
                                        {
                                            name: '',
                                            state: 'categories',
                                            routeParam: 'categorySlug',
                                            link: '/products/categorySlug',
                                        },
                                        {
                                            name: '',
                                            state: 'categories',
                                            routeParam: ['categorySlug', 'subCategorySlug'],
                                            link: '/products/categorySlug/subCategorySlug',
                                        },
                                    ]
                                },
                            }
                        ]
                    }
                ]
            },
            {
                path: "product",
                name: "Product",
                component: () =>
                    import(/* webpackChunkName: "product" */ './../views/Products/ProductEmpty.vue'),
                meta: {
                    breadcrumbs: true,
                },
                children: [
                    {
                        name: 'ProductSingle',
                        path: ':productSlug',
                        meta: {
                            breadcrumb: [
                                { name: 'Products', link: '/products' },
                                {
                                    name: '',
                                    state: 'products',
                                    routeParam: 'productSlug',
                                },
                            ],
                        },
                        component: () =>
                            import(/* webpackChunkName: "product" */ './../views/Products/Product.vue'),
                    },
                ]
            },
            {
                path: ":pathMatch(.+)",
                name: "NotFound",
                component: () =>
                    import(/* webpackChunkName: "product" */ './../views/404.vue'),
            },
        ]
    }
];


const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Set OG: tags
        document
        .querySelector('meta[property="og:url"]')
        .setAttribute("content", process.env.VUE_APP_WEBSHOP_URL + window.location.pathname);

        const localeList = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
        document
            .querySelector('meta[property="og:locale"]')
            .setAttribute("content", i18n.locale);

        if(i18n.locale == localeList[1]) {
            document
                .querySelector('meta[property="og:locale:alternate"]')
                .setAttribute("content", localeList[0]);
        } else {
            document
                .querySelector('meta[property="og:locale:alternate"]')
                .setAttribute("content", localeList[1]);
        }

        if (savedPosition) {
            return savedPosition
        } else {
            document.getElementById('app').scrollIntoView();
            return null;
        }

    }

});
import { translateIn } from '../i18n';

// AK - DISABLED CODE BELOW. But now meta data isn't set anymore. Need to rethink this solution or use Mageworkx SEO module?
//
// router.beforeEach((to, from, next) => {
//     console.log('index.js beforeEach()');
//     let meta = [];
//     to.matched.forEach(match => {
//         if (match.meta.header) {
//             if (match.meta.header.title) {
//                 meta.title = match.meta.header.title;
//             }
//             if (match.meta.header.description) {
//                 meta.description = match.meta.header.description;
//             }
//         }
//         if (match.meta.seo) {
//             if (match.meta.seo.title) {
//                 meta.title = match.meta.seo.title;
//             }
//             if (match.meta.seo.description && match.meta.seo.description != null) {
//                 meta.description = match.meta.seo.description;
//             }
//         }
//     });

//     let locale = 'en';
//     if (i18n.locale) locale = i18n.locale;
//     else if (to.params.locale) locale = to.params.locale;
//     console.log('index.js (567) (before setting meta data) locale ' + locale);

//     if (meta.title) {
//         let title = translateIn(locale, meta.title);
//         if (typeof title != "string") {
//             title = translateIn('en', meta.title);
//         }
//         document.title = typeof title == "string" ? title + " - VETUS" : "Make boating enjoyable - VETUS";
//     }
//     if (meta.description) {
//         let description = translateIn(locale, meta.description);
//         if (typeof description != "string") {
//             description = translateIn('en', meta.description);
//         }

//         if (typeof description == "string") {
//             document.querySelector('meta[name="description"]').setAttribute("content", description);
//         } else {
//             document.querySelector('meta[name="description"]').setAttribute("content", "");
//         }
//     }

//     // Remove any stale meta tags from the document using the key attribute we set below.
//     // Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

//     // Skip rendering meta tags if there are none.
//     // if (nearestWithMeta) {

//     //     // Turn the meta tag definitions into actual elements in the head.
//     //     nearestWithMeta.meta.metaTags.map(tagDef => {
//     //         const tag = document.createElement('meta');

//     //         Object.keys(tagDef).forEach(key => {
//     //             tag.setAttribute(key, tagDef[key]);
//     //         });

//     //         // We use this to track which meta tags we create so we don't interfere with other ones.
//     //         tag.setAttribute('data-vue-router-controlled', '');

//     //         return tag;
//     //     })
//     //         // Add the meta tags to the document head.
//     //         .forEach(tag => document.head.appendChild(tag));
//     //     next();
//     // }

//     if (store.getters.isLoggedIn) {
//         store.dispatch("tokenCheck");
//     }

//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         // Disable auth pages for non logged in users
//         if (!store.getters.isLoggedIn) {
//             next({
//                 name: 'Login',
//             })
//         } else {
//             next()
//         }
//     } else if (to.matched.some(record => record.meta.requiresVisitor)) {

//         if (store.getters.isLoggedIn) {
//             if (to.name == "Choose a dealer") {
//                 next({
//                     name: "Quote"
//                 })
//             } else {
//                 next({
//                     name: "Account"
//                 })
//             }
//         } else {
//             if (to.name == "Reset password" && !to.query.token) {
//                 next({
//                     name: "Home"
//                 })
//             } else {

//                 next()
//             }
//         }

//     } else {
//         // console.log('index.js (617) start');
//         // console.log('index.js (618) i18n.locale  ' + i18n.locale);
//         // console.log('index.js (619) VUE_APP_I18N_LOCALE ' + process.env.VUE_APP_I18N_LOCALE);
//         // console.log('index.js (620) to.params.locale ' + to.params.locale);

//         // if (i18n.locale !== undefined && i18n.locale != process.env.VUE_APP_I18N_LOCALE && to.params.locale == '') {
//         //     to.params.locale = i18n.locale;
//         //     next(to)
//         // } else if (i18n.locale !== undefined && i18n.locale == process.env.VUE_APP_I18N_LOCALE && to.params.locale == process.env.VUE_APP_I18N_LOCALE) {
//         //     to.params.locale = '';
//         //     next(to)
//         // } else if (to.params.locale == undefined && from.params.locale != undefined) {
//         //     to.params.locale = from.params.locale;
//         //     next(to)
//         // } else {
//         //     next()
//         // }
//         //next()
//         // console.log('index.js (XXX)');
//         // console.log(to);
//         // console.log('index.js (637) end');

//         console.log('index.js final ELSE');



//     }
// })

router.beforeEach((to, from, next) => {
    document.querySelector('meta[name="robots"]').setAttribute("content", "index,follow");
    next();
});

const supportedLangcodes = getSupportedLocales();

function getSupportedLocales() {
    var localesStr = process.env.VUE_APP_I18N_SUPPORTED_LOCALE;
    return localesStr.split(',');
}

function setLocale(to, from, next) {
    // Evaluate whether or not the URL contains a language
    const hasLocale = to.params && to.params.locale;
    const hadLocale = from.params && from.params.locale;

    // console.log('setLocale(1) hasLocale: ' + hasLocale + ' hadLocale: ' + hadLocale);
    // console.log('setLocale(1) from ');
    // console.log(from);
    // console.log('setLocale(1) to ');
    // console.log(to);

    // If the language hasn't changed since last route we're done
    if (hasLocale && hadLocale && from.params.locale.toLowerCase() === to.params.locale.toLowerCase()) {
        next();
    }

    // // Get the save language if it exists
    let locale = localStorage.locale ? localStorage.locale.toLowerCase() : 'en';
    // console.log('setLocale(2) locale: ' + locale);

    // // Overwrite the language with the route if there is one
    if (hasLocale) {
        locale = to.params.locale.toLowerCase();
    }
    // console.log('isetLocale(3) locale: ' + locale);

    // // Make sure the language is valid
    if (!supportedLangcodes.includes(locale)) {
        // console.log('setLocale(4) invalid locale!: ' + locale);
        locale = 'en';
        // return next({ name: 'NotFound', locale: 'en' });
        return next(`/${locale}/not-found`);

    }
    //console.log('setLocale(5) locale: ' + locale);

    // // Set the website language based on the URL
    i18n.locale = locale;
    localStorage.locale = locale;
    // console.log('setLocale(6) i18n.locale: ' + i18n.locale);
    // console.log('setLocale(7) localStorage.locale: ' + localStorage.locale);

    // // Redirect to a url with the language
    if (!hasLocale) {
    //    console.log('setLocale(8) to.fullPath: ' + to.fullPath);
    //    console.log('setLocale(8) ${locale}$to.fullPath: /' + locale + to.fullPath);
        return next(`/${locale}${to.fullPath}`);
    }

    //console.log('setLocale(9) next() ');


    return next();
    //return next();
}

export default router;
