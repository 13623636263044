import { GET_CATEOGIRES_QUERY } from '../../graphql/queries/categoryQueries'

const { default: Axios } = require("axios")

const state = {
    categories: {},
    isFetchingCategories: false
}

const getters = {
    categories: state => state.categories,
    isFetchingCategories: state => state.isFetchingCategories
}

const actions = {
    async fetchCategories({ commit, state }) {
        if (!state.isFetchingCategories) {
            commit('SET_FETCHING_CATEGORIES')
            try {
                const response = await Axios(GET_CATEOGIRES_QUERY())
                commit('SET_CATEGORIES', response.data.categories)
            } catch (error) {
                throw new Error('Something went wrong fetching categories');
            }
        }
    }
}

const mutations = {
    SET_CATEGORIES: (state, categories) => {
        state.categories = {};

        // Since magento has a "default" category we skip this for our data. We start with its children
        let children = categories.items[0].children;
        //console.log(children);
        // Key by url_key to easily retrieve the data we want
        children.sort((a, b) => (a.position > b.position) ? 1 : -1)
        children.forEach(function (child) {
            var subChildren = {};
            child.children.sort((a, b) => (a.position > b.position) ? 1 : -1)
            child.children.forEach(function (subChild) {
                subChildren[subChild.url_key] = subChild;
            });
            child.children = subChildren;
            state.categories[child.url_key] = child;
        })
        state.isFetchingCategories = false;
    },
    SET_FETCHING_CATEGORIES: (state) => {
        state.isFetchingCategories = true;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}