import { GET_PRODUCTS_QUERY, GET_PRODUCT_QUERY, GET_SEARCH_PRODUCTS_QUERY } from '../../graphql/queries/productQueries'

const { default: Axios } = require("axios")

const state = {
    products: {},
    singleProduct: {},
    searchProducts: {},
    isFetchingProducts: {},
    isFetchingProduct: false
}

const getters = {
    products: state => state.products,
    searchProducts: state => state.searchProducts,
    singleProduct: state => state.singleProduct,
    isFetchingProducts: state => state.isFetchingProducts,
    isFetchingProduct: state => state.isFetchingProduct
}

const actions = {
    async fetchProducts({ commit, state }, data) {
        if (typeof state.isFetchingProducts[data.categoryId] === 'undefined' || !state.isFetchingProducts[data.categoryId]) {
            commit('SET_FETCHING_PRODUCTS', data.categoryId)
            try {
                const response = await Axios(GET_PRODUCTS_QUERY(data.categoryId, data.filters))
                commit('SET_PRODUCTS', { products: response.data.products, categoryId: data.categoryId })
            } catch (error) {
                throw new Error('Something went wrong fetching products: ' + error.message);
            }
        }
    },
    async fetchSearchProducts({ commit }, data) {
        try {
            const response = await Axios(GET_SEARCH_PRODUCTS_QUERY(data.sortOption, data.pageNumber, data.searchValue))
            commit('SET_SEARCH_PRODUCTS', response.data.products)
        } catch (error) {
            throw new Error('Something went wrong fetching products');
        }
    },
    async fetchSingleProduct({ commit, state }, data) {
        if (!state.isFetchingProduct) {
            commit('SET_FETCHING_PRODUCT')
            try {
                const response = await Axios(GET_PRODUCT_QUERY(data.url_key))
                commit('SET_SINGLE_PRODUCT', { products: response.data.products.items, url_key: data.url_key })
            }
            catch (error) {
                throw new Error('Something went wrong fetching product');
            }
        }
    },
}

const mutations = {
    SET_PRODUCTS: (state, data) => {
        // console.log(data.products);
        let aggregations = {};
        if (data.products.aggregations) {
            data.products.aggregations.forEach(function (aggregation) {
                let options = {};
                aggregations[aggregation.attribute_code] = aggregation;
                aggregation.options.forEach(option => {
                    options[option.value] = option
                })
                aggregations[aggregation.attribute_code].options = options;
            })
        }
        data.products.aggregations = aggregations;
        state.products[data.categoryId] = data.products
        state.isFetchingProducts[data.categoryId] = false;
    },
    SET_SEARCH_PRODUCTS: (state, products) => { state.searchProducts = products },
    SET_SINGLE_PRODUCT: (state, data) => {
        data.products.forEach(function (product) {
            if (product.url_key == data.url_key) {
                state.singleProduct[product.url_key] = product;
            }
        })
        state.isFetchingProduct = false;
    },
    SET_FETCHING_PRODUCTS: (state, categoryId) => {
        state.isFetchingProducts[categoryId] = true;
    },
    SET_FETCHING_PRODUCT: (state) => {
        state.isFetchingProduct = true;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
