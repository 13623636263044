export const RETRIEVE_CUSTOMER_QUERY = () => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `{
                customer{
                    firstname
                    lastname
                    suffix
                    prefix
                    middlename
                    email
                    addresses {
                        id
                        default_billing
                        default_shipping
                        firstname
                        lastname
                        company
                        suffix
                        prefix
                        middlename
                        street
                        city
                        region {
                            region_code
                            region
                        }
                        postcode
                        country_code
                        telephone
                    }
                }
            }`
        }
    }
}
