{
    "home": {
        "header": {
            "title": "E-DRIVES",
            "subtitle": "De elektrische vaaroplossingen van VETUS",
            "image": "https://vetus.com/cms/wp-content/uploads/e-pod_e-line_voor_web_2048x955px-1.png",
            "button_label": "Ontdek de E-DRIVES",
            "button_url": "https://vetus.com/nl/e-drives/"
        },
        "categories": {
            "title": "VETUS Systems",
            "content": ""
        },
        "title": "Maakt varen plezierig",
        "description": ""
    },
    "account": {
        "login": {
            "title": "Inloggen bij VETUS",
            "description": "",
            "seo": {
                "title": "Inloggen bij VETUS",
                "description": ""
            },
            "email-label": "E-mailadres",
            "email-placeholder": "Uw e-mailadres",
            "password-label": "Wachtwoord",
            "password-placeholder": "Jouw wachtwoord",
            "forgot-password": "Wachtwoord vergeten?",
            "sign-in": "Log in",
            "no-account": "Nog geen account?",
            "request-access-button-label": "Toegang aanvragen",
            "request-access-button-url": "https://vetus.com/nl/service-support/contactformulier/",
            "error": "Er is iets misgegaan:",
            "sign-in-incorrect": "Aanmelding account was onjuist"
        },
        "forgot-password": {
            "title": "Wachtwoord vergeten?",
            "description": "Vul hieronder uw e-mailadres in. U zult een link ontvangen om uw wachtwoord te herstellen.",
            "seo": {
                "title": "Wachtwoord herstellen",
                "description": ""
            },
            "back-to-sign-in": "Terug naar aanmelden",
            "request-granted": "Wachtwoord reset aangevraagd",
            "error": "Wachtwoord reset aanvragen mislukt",
            "submit": "Verzenden"
        },
        "reset-password": {
            "title": "Wachtwoord herstellen",
            "description": "Vul hieronder uw e-mailadres in. U zult een link ontvangen om uw wachtwoord te resetten.",
            "seo": {
                "title": "Wachtwoord resetten",
                "description": ""
            },
            "email-label": "E-mailadres",
            "email-placeholder": "E-mailadres",
            "new-password-label": "Nieuw wachtwoord",
            "new-password-placeholder": "Voer een nieuw wachtwoord in",
            "back-to-sign-in": "Terug naar aanmelden",
            "submit": "Verzenden",
            "error": "Er is iets misgegaan:",
            "success": "Wachtwoord succesvol gewijzigd"
        },
        "sidemenu": {
            "account": "Account",
            "orders": "Web bestellingen",
            "processed-orders": "Offline bestellingen",
            "address-book": "Adresboek"
        },
        "sidebar": {
            "my-account": "Mijn account"
        },
        "dashboard": {
            "title": "Dashboard",
            "description": "",
            "seo": {
                "title": "Dashboard",
                "description": ""
            },
            "greeting": "Hallo, {name}",
            "content": "Vanuit uw account dashboard heeft u de mogelijkheid om een overzicht te krijgen van uw recente activiteiten. Selecteer de link voor het bewerken en het inzien van account informatie",
            "recent-order": {
                "title": "Laatste bestelling",
                "view-all": "Bekijk alles"
            },
            "account": {
                "information": "Accountinformatie",
                "contact": "Contactinformatie",
                "change-password": "Wijzig wachtwoord",
                "newsletters": "Nieuwsbrief",
                "not-subscribed": "U bent niet ingeschreven voor de nieuwsbrief",
                "edit": "Wijzig"
            },
            "address-book": {
                "title": "Adresboek",
                "manage-address": "Adres beheren",
                "default-billing": "Factuuradres",
                "no-billing": "Factuuradres ontbreekt",
                "default-shipping": "Afleveradres",
                "edit": "Wijzig",
                "not-subscribed": "U bent niet ingeschreven voor de nieuwsbrief",
                "request-a-change": {
                    "title": "Wijziging aanvragen",
                    "url": "https://vetus.com/nl/service-support/contactformulier/"
                }
            }
        },
        "orders": {
            "title": "Bestellingen",
            "description": "",
            "seo": {
                "title": "Bestellingen",
                "description": ""
            },
            "search-placeholder": "Zoeken op bestelnummer",
            "show-more": "Toon meer"
        },
        "processed-orders": {
            "title": "Bestellingen",
            "description": "",
            "seo": {
                "title": "Bestellingen",
                "description": ""
            },
            "search-placeholder": "Zoeken op bestelnummer",
            "show-more": "Toon meer"
        },
        "address-book": {
            "default-billing": "Factuuradres",
            "request-change": "Verzoek wijziging",
            "default-shipping": "Afleveradres",
            "shipping-dropdown": "Open keuzemenu",
            "save": "Afleveradres opslaan",
            "title": "Adresboek",
            "seo": {
                "title": "Adresboek",
                "description": ""
            },
            "description": ""
        }
    },
    "search": {
        "title": "Zoek",
        "description": "",
        "seo": {
            "title": "Zoek",
            "description": ""
        },
        "search-results": "Zoekresultaten"
    },
    "miscellaneous": {
        "labels": {
            "choose-variant": "Kies type",
            "quantity": "Aantal",
            "catalog-price": "Catalogusprijs",
            "discount-rate": "Kortingspercentage",
            "in-stock": "Op voorraad",
            "out-of-stock": "Niet voorradig",
            "remove": "Verwijder",
            "sort-by": "Sorteer op",
            "relevance": "Relevantie",
            "name-a-z": "Naam A-Z",
            "name-z-a": "Naam Z-A",
            "product": "Product",
            "contact-info": "Contactinformatie",
            "loading": "Laden",
            "no-results": "Geen resultaten"
        },
        "buttons": {
            "add-to-cart": "Toevoegen aan winkelwagen",
            "add-to-quote": "Toevoegen aan offerte",
            "show-more": "Toon meer",
            "show-less": "Toon minder",
            "filter": "Filter"
        },
        "titles": {
            "related-products": "Gerelateerde producten",
            "description": "Omschrijving",
            "specifications": "Specificaties"
        }
    },
    "shop": {
        "cart": {
            "title": "Winkelwagen",
            "description": "",
            "product-added": "Product toegevoegd aan",
            "seo": {
                "title": "Winkelwagen",
                "description": ""
            },
            "total": "Totaal",
            "empty": "Uw winkelwagen is leeg. Bezoek de webshop om producten aan de winkelwagen toe te voegen",
            "proceed": "Ga verder naar de kassa"
        },
        "cart-popup": {
            "title": "Producten toegevoegd aan winkelwagen",
            "continue": "Ga door met winkelen",
            "view": "Bestellingen bekijken",
            "optional": "U kunt ook"
        },
        "quote-popup": {
            "view": "Bekijk offerte"
        },
        "checkout": {
            "title": "Afrekenen",
            "description": "",
            "seo": {
                "title": "Afrekenen",
                "description": ""
            }
        },
        "quote": {
            "title": "Afrekenen",
            "description": "",
            "seo": {
                "title": "Afrekenen",
                "description": ""
            },
            "proceed": "Ga verder naar contact info",
            "proceed-dealer": "Ga door naar het kiezen van een dealer",
            "empty": "Uw offerte is leeg. Bezoek de webshop om producten toe te voegen aan uw offerte",
            "check-the-products": {
                "tab": "Controleer uw producten"
            },
            "choose-a-dealer": {
                "tab": "Kies een dealer",
                "title": "Kies een dealer",
                "description": "",
                "seo": {
                    "title": "Kies een dealer",
                    "description": ""
                }
            },
            "contact-info": {
                "tab": "Contactinformatie",
                "title": "Contactinformatie",
                "description": "",
                "seo": {
                    "title": "Contactinformatie",
                    "description": "Contactinformatie beschrijving"
                }
            },
            "thanks-title": "Bedankt voor uw offereaanvraag!",
            "request-number": "Uw offerteaanvraag is:",
            "confirmation": "U ontvangt een bevestigingsmail met de informatie van uw offerte"
        }
    },
    "section": {
        "intro": ""
    },
    "header": {
        "usp": {
            "usp1": "Meer dan 55 jaar ervaring",
            "usp2": "Wereldwijde leverancier",
            "usp3": "5 wereldwijde magazijnen",
            "usp4": "5 PLUS garantie"
        },
        "units": {
            "units": "Eenheden",
            "metric-selected": "Metriek",
            "metric-option": "Metriek (cm, liter)",
            "imperial-selected": "Imperiaal",
            "imperial-option": "Imperiaal (inch, gallon)"
        },
        "websites": {
            "corporate": "Bedrijf",
            "commercial": "Commercieel",
            "webshop": "Producten"
        },
        "menu": {
            "home": "Startpagina",
            "products": "Producten",
            "all-products": "Bekijk alle producten",
            "outlet": "Uitverkoop",
            "service-support": "Service & Support",
            "partsfinder": "Onderdelen zoeken",
            "cart": "Winkelwagen",
            "quote": "Offerte",
            "my-profile": "Mijn profiel",
            "account": "Account",
            "login": "Inloggen",
            "logout": "Uitloggen",
            "search": "Zoeken",
            "search-bar": "Open zoekbalk"
        },
        "about-us": "Over ons",
        "creator-of": "Makers van",
        "boat-systems": "Bootsystemen",
        "about-intro": ""
    },
    "footer": {
        "products-title": "Producten",
        "my-profile": "Mijn VETUS",
        "cart": "Winkelwagen"
    },
    "languages": {
        "language": "Taal",
        "en": "Engels",
        "nl": "Nederlands"
    },
    "newsletter": {
        "title": "Inschrijven voor nieuwsbrief",
        "content": "nieuws",
        "placeholder": "Uw e-mailadres",
        "subscribe": "Aanmelden",
        "link": "#"
    },
    "404-not-found": {
        "title": "Oeps! Je bent momenteel in onbekende wateren",
        "content": "Deze pagina is verdwenen of de URL bestaat niet meer",
        "button": "Terug naar start"
    },
    "products": {
        "title": "Producten",
        "description": "",
        "seo": {
            "title": "Producten",
            "description": ""
        },
        "errors": {
            "select-variant": "Selecteer een variant om verder te gaan"
        }
    },
    "order": {
        "billing-address": "Factuuradres",
        "shipping-address": "Verzendadres",
        "reference": "Uw referentie",
        "first-name": "Voornaam",
        "last-name": "Achternaam",
        "first-name-mand": "Voornaam *",
        "last-name-mand": "Achternaam *",
        "email": "E-mail",
        "email-mand": "E-mail*",
        "share-email": "We zullen uw e-mail nooit met iemand anders delen.",
        "address": "Adres",
        "address-mand": "Adres *",
        "state-province": "Staat/provincie",
        "state-province-mand": "Staat/provincie*",
        "postal-code": "Postcode",
        "postal-code-mand": "Postcode *",
        "phone": "Telefoonnummer",
        "city-town": "Stad / plaats",
        "city-town-mand": "Stad / plaats*",
        "country": "Land",
        "country-mand": "Land*",
        "company": "Bedrijf",
        "optional": "Optioneel",
        "download-invoice": "Download factuur",
        "new-shipping-address": "Nieuw afleveradres toevoegen",
        "submit-request": "Verzoek indienen",
        "no-billing-address": "Geen factuuradres gevonden",
        "shipping-method": "Verzend methoden",
        "no-shipping-method": "Geen verzendmethode gevonden.",
        "no-shipping-address": "Geen verzendadres gevonden.",
        "payment-method": "Betaalmethode",
        "no-payment-method": "Geen betalingsmethode gevonden.",
        "items-ordered": "Bestelde artikelen",
        "subtotal": "Subtotaal",
        "shipping-handling": "Verzending en behandeling",
        "estimate-shipping-handling": "Schatting van verzend- en administratiekosten",
        "carriage": "Vervoer betaald aan - Verzendkosten worden berekend na verwerking",
        "tax": "belasting",
        "order-summary": "Overzicht bestelling",
        "adjust": "Toevoegen",
        "request-total": "Verzoek totaal",
        "thanks": "Bedankt voor uw aankoop",
        "order-received": "Bestelling ontvangen",
        "order-number": "Bestelnummer",
        "order-hash": "Bestelling #",
        "status": "Status",
        "view-order": "Bekijk bestelling",
        "order-confirmation": "Bestellingsbevestiging",
        "about-order": "Over deze bestelling",
        "errors": {
            "something-wrong": "Er is iets fout gegaan. Probeer het later nog eens of neem contact met ons op.",
            "something-wrong-shipping": "Er is iets fout gegaan. Probeer het later nog eens of neem contact met ons op.",
            "something-wrong-shipping-method": "Er is iets fout gegaan. Probeer het later nog eens of neem contact met ons op.",
            "something-wrong-billing": "Er is iets fout gegaan. Probeer het later nog eens of neem contact met ons op.",
            "something-wrong-payment": "Er is iets fout gegaan. Probeer het later nog eens of neem contact met ons op."
        }
    },
    "processed-order": {
        "order-number": "Bestelnummer",
        "status": "Status",
        "delivery-date": "Verwachte leveringsdatum",
        "download-invoice": "Download factuur"
    },
    "contact-info": {
        "comment": "Commentaar",
        "newsletter-signup": "Ja ik wil mij abonneren op de nieuwsbrief en de laatste informatie ontvangen over aannbiedingen, promoties en nieuws",
        "request-send": "Het verzoek zal worden verstuurd naar",
        "quote": "Uw offerte is leeg. Bezoek de webshop om producten toe te voegen aan uw offerte",
        "errors": {
            "something-wrong": "Er ging iets mis bij het indienen van uw offerte."
        }
    },
    "carousel": {
        "previous": "Vorige",
        "next": "Volgende"
    },
    "store-locator": {
        "reset-filters": "Reset filter",
        "enter-dealer-name": "Voer de naam van de dealer in",
        "postal-code-city-country": "Postcode, stad of land",
        "choose-dealer-for-quote": "Kies dealer voor een offerte",
        "no-results": "Geen resultaat",
        "title": "Meer dan 1600 filialen",
        "intro": "",
        "search-location": "Zoek op locatie",
        "search-name": "Zoek op dealer naam"
    },
    "events": {
        "latest-events": "Laatste evenementen",
        "all-events-button-label": "Alle evenementen",
        "all-events-button-url": "https://vetus.com/nl/boot-show/",
        "past-event": "Afgelopen evenementen"
    },
    "news": {
        "latest-news": "Laatste nieuws",
        "all-news-button-label": "Alle nieuws",
        "all-news-button-url": "https://vetus.com/nl/nieuws/"
    }
}
