export const DEFAULT_ADDRESS = `{
    firstname
    lastname
    company
    street
    city
    region
    postcode
    telephone
    country_code
}`