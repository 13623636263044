// export const SUBMIT_QUOTE_QUERY = (quoteDealer, products) => {
export const SUBMIT_QUOTE_QUERY = (data, quote) => {
    let items = [];
    data.items.forEach(function (element) {
        items.push(`{ sku: "${element.sku}", quantity: ${quote[element.sku]} }`);
    })

    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation{
                createQuoteRequest(
                    input: {
                        dealer_id: ${data.dealer_id},
                        items: [${items.join(",")}],
                        subtotal: 0,
                        address: {
                            firstname: "${data.address.firstname}",
                            lastname: "${data.address.lastname}",
                            company: "${data.address.company}",
                            street: ["${data.address.street}"],
                            city: "${data.address.city}",
                            region_id: ${data.address.region_id},
                            region: null,
                            postcode: "${data.address.postcode}",
                            country_code: "${data.address.country_code}",
                            telephone: "${data.address.telephone}"
                        },
                        email: "${data.email}",
                        remarks: """${data.remarks}""",
                        subscribe_newsletter: ${data.subscribe_newsletter}
                    }
                ) {
                    quote_request {
                        number
                    }
                }
            }
            `
        }
    }
}
// export const SUBMIT_QUOTE_QUERY = (quoteDealer, products) => {
// export const SUBMIT_QUOTE_QUERY = (data) => {
//     let items = [];
//     data.items.forEach(function (element) {
//         items.push(`{ sku: "${element.sku}", quantity: ${element.quantity} }`);
//     })


//     console.log(items);
//     return {
//         url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
//         method: 'POST',
//         data: {
//             query: `mutation{
//                 createQuoteRequest(
//                     input: {
//                         dealer_id: ${data.dealer_id},
//                         items: [${items.join(",")}],
//                         subtotal: 0,
//                         address: {
//                             firstname: "${data.address.firstname}",
//                             lastname: "${data.address.lastname}",
//                             company: "${data.address.company}",
//                             street: ["${data.address.street}"],
//                             city: "${data.address.city}",
//                             region: null,
//                             region_id: 0,
//                             postcode: "${data.address.postcode}",
//                             country_code: "${data.country_code}",
//                             telephone: "${data.address.telephone}"
//                         },
//                         email: "${data.email}",
//                         remarks: "${data.remarks}",
//                         subscribe_newsletter: ${data.subscribe_newsletter}
//                     }
//                 ) {
//                     quote_request {
//                         number
//                     }
//                 }
//             }
//             `
//         }
//     }
// }
