import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios';
import store_map from './i18n/store_map.json';
import "@/assets/scss/style.scss";
import i18n from './i18n';
import { createGtm } from '@gtm-support/vue-gtm';

require("@/store/modules/user");

axios.interceptors.response.use(response => {

    if (response.data.data) {
        let newResponse = {
            data: response.data.data,
            errors: (typeof response.data.errors != undefined ? response.data.errors : null)
        };
        return newResponse;
    }
    return response;
});

const app = createApp(App);

// Place after app mount for debugging purposes
store.dispatch("attempt", localStorage.getItem("token"))

app
    .use(store)
    .use(i18n)
    .use(router);

if (process.env.VUE_APP_GTM) {
    app
        .use(
            //https://www.npmjs.com/package/@gtm-support/vue-gtm
            createGtm({
                id: process.env.VUE_APP_GTM,
                queryParams: {
                },
                defer: false,
                compatibility: false,
                nonce: '2726c7f26c',
                enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                debug: true,
                loadScript: true,
                vueRouter: router,
                trackOnNextTick: false,
            }));
}

app.mount("#app");


app.config.globalProperties.axios = axios

axios.interceptors.request.use(config => {
    if (i18n.locale) {
        config.headers['Store'] = store_map[i18n.locale];
    } else {
        config.headers['Store'] = store_map['en'];
    }
    return config;
});
