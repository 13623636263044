export const SIGN_IN_QUERY = (email, password) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                generateCustomerToken(
                    email: "${email}"
                    password: "${password}"
                ) {
                    token
                }

            }`
        }
    }

    // return `mutation {
    //     generateCustomerToken(
    //         email: "${email}"
    //         password: "${password}"
    //     ) {
    //         token
    //     }

    // }`
}

export const SEND_RESET_PASSWORD_QUERY = (data) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                requestPasswordResetEmail(
                    email: "${data}"
                )
            }`
        }
    }
}

export const RESET_PASSWORD_QUERY = (data) => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `mutation {
                resetPassword(
                    email: "${data.email}"
                    resetPasswordToken: "${data.token}"
                    newPassword: "${data.new_password}"
                )
            }`
        }
    }
}