import store from "@/store";
import Axios from "axios";

store.subscribe((mutation) => {
    switch (mutation.type) {
        case "SET_TOKEN":
            if (mutation.payload.token) {
                Axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload.token}`;
                localStorage.setItem("token", mutation.payload.token);
            } else {
                Axios.defaults.headers.common['Authorization'] = null;
                localStorage.removeItem("token");
            }
            break;
    }
});