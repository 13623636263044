<template>
  <router-view />
</template>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@500;600;700&amp;family=Inter:wght@400;500;600;700&amp;display=swap");

#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
