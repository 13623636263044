export const RETRIEVE_COUNTRIES_QUERY = () => {
    return {
        url: process.env.VUE_APP_MAGENTO_GRAPHQL_URL,
        method: 'POST',
        data: {
            query: `{
                countries {
                    id
                    two_letter_abbreviation
                    three_letter_abbreviation
                    full_name_locale
                    full_name_english
                    available_regions {
                        id
                        code
                        name
                    }
                }
            }`
        }
    }
}
