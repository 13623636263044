const { default: Axios } = require("axios")

const state = {
    processedOrders: {},
    processedOrder: {},
    isFetchingProcessedOrders: false,
    isFetchingProcessedOrder: false,
}

const getters = {
    processedOrders: state => state.processedOrders,
    processedOrder: state => state.processedOrder,
    isFetchingProcessedOrders: state => state.isFetchingProcessedOrders,
    isFetchingProcessedOrder: state => state.isFetchingProcessedOrder
}

const actions = {
    async retrieveProcessedOrders({ commit }, data) {
        if (!state.isFetchingProcessedOrders) {
            commit('SET_FETCHING_PROCESSED_ORDERS')

            const response = await Axios({
                url: process.env.VUE_APP_MAGENTO_NAV_URL + '/json',
                method: 'GET',
                params: {
                    'menu_id': 'menu_1',
                    'customer_token': localStorage.getItem("token")
                }
            });

            // Filtering afterwards
            if (data !== undefined && data.search !== undefined) {
                response.data.rows = response.data.rows.filter(row => {
                    return row.No.includes(data.search);
                });
            }

            // Map
            const orders = response.data.rows.map(item => ({
                'no': item.No,
                'date': item.Order_Date,
                'price': {
                    'value': parseFloat(item.Amount),
                    'currency': 'EUR'
                },
                'priceIncl': {
                    'value': parseFloat(item.Amount_Including_VAT),
                    'currency': 'EUR'
                },
                'discount': {
                    'vlue': parseFloat(item.Invoice_Discount_Amount),
                    'currency': 'EUR'
                },
                'billingAddress': {
                    'name': item.Bill_to_Name + ' ' + item.Bill_to_Name_2,
                    'address': item.Bill_to_Address,
                    'city': item.Bill_to_City,
                    'postcode': item.Bill_to_Post_Code,
                    'country': item.Bill_to_Country_Region_Code
                },
                'shippingAddress': {
                    'name': item.Ship_to_Name + ' ' + item.Ship_to_Name_2,
                    'address': item.Ship_to_Address,
                    'city': item.Ship_to_City,
                    'postcode': item.Ship_to_Post_Code,
                    'country': item.Ship_to_Country_Region_Code,
                    'contact': item.Ship_to_Contact
                },
                'status': item.Workflow_Status_Code,
                'reference': item.Your_Reference,
                'latestDeliveryDate': item.Latest_Delivery_Date,
                'lines': []
            }));

            commit("SET_PROCESSED_ORDERS", orders);

        }
    },
    async retrieveProcessedOrder({ commit }, data) {
        if (!state.isFetchingProcessedOrder) {
            commit('SET_FETCHING_PROCESSED_ORDER')

            // All orders
            const orders = await Axios({
                url: process.env.VUE_APP_MAGENTO_NAV_URL + '/json',
                method: 'GET',
                params: {
                    'menu_id': 'menu_1',
                    'customer_token': localStorage.getItem("token")
                }
            });

            // Get current order
            const order = orders.data.rows.filter(row => {
                return row.No == data.no;
            }).shift();

            // Order lines
            const orderlines = await Axios({
                url: process.env.VUE_APP_MAGENTO_NAV_URL + '/jsonsubform',
                method: 'GET',
                params: {
                    'menu_id': 'menu_1',
                    'customer_token': localStorage.getItem("token"),
                    'resource_type': 'Document_No',
                    'resource_value': data.no
                }
            });

            // Map
            const item = {
                //.map(item => ({
                'no': order.No,
                'date': order.Order_Date,
                'price': {
                    'value': parseFloat(order.Amount),
                    'currency': 'EUR'
                },
                'priceIncl': {
                    'value': parseFloat(order.Amount_Including_VAT),
                    'currency': 'EUR'
                },
                'tax': {
                    'value': parseFloat(order.Amount_Including_VAT - order.Amount),
                    'currency': 'EUR'
                },

                'discount': {
                    'value': parseFloat(order.Invoice_Discount_Amount),
                    'currency': 'EUR'
                },
                'billingAddress': {
                    'name': order.Bill_to_Name + ' ' + order.Bill_to_Name_2,
                    'address': order.Bill_to_Address,
                    'city': order.Bill_to_City,
                    'postcode': order.Bill_to_Post_Code,
                    'country': order.Bill_to_Country_Region_Code
                },
                'shippingAddress': {
                    'name': order.Ship_to_Name + ' ' + order.Ship_to_Name_2,
                    'address': order.Ship_to_Address,
                    'city': order.Ship_to_City,
                    'postcode': order.Ship_to_Post_Code,
                    'country': order.Ship_to_Country_Region_Code,
                    'contact': order.Ship_to_Contact
                },
                'status': order.Workflow_Status_Code,
                'reference': order.Your_Reference,
                'latestDeliveryDate': order.Latest_Delivery_Date,
                'items': orderlines.data.rows.map(function (item) {
                    return {
                        id: item.No,
                        description: item.Description,
                        quantity: item.Quantity,
                        unitPrice: parseFloat(item.Unit_Price),
                        totalPrice: parseFloat(item.Unit_Price * item.Quantity),
                    };
                })
            };

            commit("SET_PROCESSED_ORDER", item);
        }
    },
    async redirectToProcessedOrderInvoice({ commit }, data) {
        let invoiceUrl = `${process.env.VUE_APP_MAGENTO_NAV_URL}/jsonprintpdf?menu_id=menu_1&customer_token=${localStorage.getItem("token")}&resource_type=invoiceNo&resource_value=${data.no}`;
        window.open(invoiceUrl);
    }
}

const mutations = {
    SET_PROCESSED_ORDERS(state, data) {
        // state.processedOrders = data.rows.reverse();
        state.processedOrders = data.reverse();
        //state.processedOrdersCount = orders.count;
        state.isFetchingProcessedOrders = false;
    },
    SET_PROCESSED_ORDER(state, order) {
        state.processedOrder[order.no] = order;
        state.isFetchingProcessedOrder = false;
    },
    SET_FETCHING_PROCESSED_ORDERS: (state,) => {
        state.isFetchingProcessedOrders = true;
    },
    SET_FETCHING_PROCESSED_ORDER: (state) => {
        state.isFetchingProcessedOrder = true;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
