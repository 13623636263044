import { createStore } from "vuex";
import categories from './modules/categories';
import products from './modules/products';
import breadcrumbs from './modules/breadcrumbs';
import auth from './modules/auth';
import cart from './modules/cart';
import orders from './modules/orders';
import processedOrders from './modules/processedOrders';
import dealers from './modules/dealers';
import quote from './modules/quote';
import countries from './modules/countries';

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        categories,
        products,
        breadcrumbs,
        auth,
        cart,
        orders,
        processedOrders,
        dealers,
        quote,
        countries
    }
});
