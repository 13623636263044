import { SIGN_IN_QUERY } from '../../graphql/queries/authQueries'
import { RETRIEVE_CUSTOMER_QUERY } from '../../graphql/queries/customerQueries'
import { SEND_RESET_PASSWORD_QUERY } from '../../graphql/queries/authQueries'
import { RESET_PASSWORD_QUERY } from '../../graphql/queries/authQueries'
const { default: Axios } = require("axios")

const state = {
    token: localStorage.getItem("token") || null,
    user: null,
    status: null
}

const getters = {
    // token: (state) => state.token,
    authStatus: state => state.status,
    isLoggedIn: state => !!state.token,
    authUser: state => state.user,
}

// email: "roni_cost@example.com",
// password: "roni_cost3@example.com"
const actions = {
    async signIn({ dispatch, commit }, data) {

        if (data.email == "loginascustomer@vetus.com") {
            dispatch("attempt", data.password);
            localStorage.setItem('token', data.password);
            dispatch('fetchCartId');
            return {};
        }

        const response = await Axios(SIGN_IN_QUERY(data.email, data.password))

        if (response.errors) {
            commit("SET_TOKEN", { token: null, status: "Fail" });
            localStorage.removeItem('token');
            throw new Error('Exception message');
        } else {
            dispatch("attempt", response.data.generateCustomerToken.token);
            localStorage.setItem('token', response.data.generateCustomerToken.token);
            dispatch('fetchCartId');
            return response.data;
        }
    },

    async tokenCheck({ dispatch, commit, state }) {
        if (!localStorage.getItem("token") || localStorage.getItem("token") == null || localStorage.getItem("token") != state.token) {
            commit("SET_TOKEN", { token: null, status: "Fail" });
            commit('SET_USER', null);
            dispatch("signOut");
        }
    },

    async attempt({ commit, state }, token) {
        if (token) {
            commit("SET_TOKEN", { token: token, status: "Success" });
        }

        if (!state.token) {
            return;
        }

        try {
            const response = await Axios(RETRIEVE_CUSTOMER_QUERY())

            if (response.data.customer == null) {
                throw new Error('Exception message');
            }
            commit('SET_USER', response.data.customer);

        } catch (error) {
            this.dispatch('signOut', null);
        }
    },

    async signOut({ dispatch, commit }) {
        commit('SET_TOKEN', { token: null, status: "Fail" });
        commit('SET_USER', null);
        // commit('SET_GUEST_CART_ID', null, { root: true })
        localStorage.removeItem('guest_cart_id');
        dispatch('emptyCart'); // modules/cart.js
        localStorage.removeItem('token');
    },

    async sendPasswordReset({ commit }, data) {
        const response = await Axios(SEND_RESET_PASSWORD_QUERY(data));
        return response;
    },

    async passwordReset({ commit }, data) {
        const response = await Axios(RESET_PASSWORD_QUERY(data));
        return response;
    }
}

const mutations = {
    SET_TOKEN(state, payload) {
        state.token = payload.token,
            state.status = payload.status
    },

    SET_USER(state, user) {
        state.user = user;

        // User company as display name
        if (user.addresses.length > 0) {
            const address = user.addresses.find(address => address.default_billing);
            state.user.displayName = address ? address.company : user.addresses[0].company;
        }

        // Otherwise fall back to lastname
        else
            state.user.displayName = user.lastname;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
